<template>
<div style="background-color: #eef2fc; height: 100vh;">
    <div style="padding-bottom: 30px;padding-top: 30px;">
        <van-nav-bar style="  position: fixed;   top: 0;   left: 0;   width: 100%;   background-color: #edf2fd; " title="我的" left-arrow @click-left="$router.back()" />
        <!-- <div style="height: 46px"></div> -->
        <div style="display: flex; margin: 0.625rem">
            <van-image round width="1.875rem" height="1.875rem" :src="$store.state.userInfo.avatar ? $store.state.userInfo.avatar : '/static/image/imageAvatar02@3x.png'" />
            <div style=" font-size: 0.375rem;  display: flex;  flex-direction: column;   justify-content: center;  ">
                <span v-if="$store.state.token" style="margin-left: 0.3125rem; font-size: 0.5rem">{{ $store.state.userInfo.username }}</span>
                <span v-else style="margin-left: 0.3125rem; font-size: 0.5rem" @click="$parent.goNav('/login')">登陆</span>
                <span v-if="$store.state.token" style="color: #c0bfc0; margin-left: 0.3125rem; margin-top: 0.3125rem" @click="copyText($store.state.userInfo.uid)">uid:{{ $store.state.userInfo.uid }}</span>
                <span style="color: #c0bfc0; margin-left: 0.3125rem; margin-top: 0.3125rem" @click="copyText($store.state.appInfo.h5_url)">永久地址:{{ $store.state.appInfo.h5_url }}</span>
            </div>
        </div>
        <div class="wallet_div">
            <div style="display: flex; flex-direction: column">
                <div style="font-size: 0.375rem">
                    中心钱包
                    <van-icon v-if="moneyShow" name="eye-o" size="0.4rem" @click="changMoneyShow" />
                    <van-icon v-else name="closed-eye" size="0.4rem" @click="changMoneyShow" />
                </div>
                <div style="font-size: 0.5rem; margin-top: 0.25rem">
                    <div v-if="moneyShow">
                        ￥{{ ($store.state.token && $store.state.userInfo.balance)  ? $store.state.userInfo.balance : "0.00"}}
                        <!-- <van-icon name="replay" size="0.4rem" /> -->

                    </div>
                    <div v-else>
                        ****
                    </div>

                </div>
            </div>
            <div style="display: flex">
                <div style="text-align: center" @click="$parent.goNav('/recharge')">
                    <van-image round width=".9375rem" height=".9375rem" src="static/image/userCenter/bank.png" />
                    <div style="font-size: 0.375rem; margin-top: 0.125rem">存款</div>
                </div>
                <div style="text-align: center; margin: 0 0.5rem" @click="$parent.goNav('/transfer')">
                    <van-image round width=".9375rem" height=".9375rem" src="static/image/userCenter/transfer.png" />
                    <div style="font-size: 0.375rem; margin-top: 0.125rem">转账</div>
                </div>
                <div style="text-align: center" @click="$parent.goNav('/withdrawal')">
                    <van-image round width=".9375rem" height=".9375rem" src="static/image/userCenter/deposit.png" />
                    <div style="font-size: 0.375rem; margin-top: 0.125rem">取款</div>
                </div>
            </div>
        </div>
        <div class="quick_bar">
            <div style="text-align: center;" @click="$parent.goNav('/money')">
                <van-image round width=".9375rem" height=".9375rem" src="static/image/userCenter/package.png" />
                <div style="font-size: 0.375rem">我的钱包</div>
            </div>
            <div style="text-align: center" @click="$parent.goNav('/transRecord')">
                <van-image round width=".9375rem" height=".9375rem" src="static/image/userCenter/document.png" />
                <div style="font-size: 0.375rem">交易记录</div>
            </div>
            <div style="text-align: center" @click="$parent.goNav('/betRecord')">
                <van-image round width=".9375rem" height=".9375rem" src="static/image/2.c21324096c848ea53d629c3f8fc20631.png" />
                <div style="font-size: 0.375rem">投注记录</div>
            </div>
            <div style="text-align: center" @click="$parent.goNav('/fanshui')">
                <van-image round width=".9375rem" height=".9375rem" src="static/image/userCenter/fs.png" />
                <div style="font-size: 0.375rem">返水记录</div>
            </div>
            <div style="text-align: center" @click="$parent.goNav('/activityRecord')">
                <van-image round width=".9375rem" height=".9375rem" src="static/image/userCenter/hd.png" />
                <div style="font-size: 0.375rem">活动记录</div>
            </div>
            <div v-if="$store.state.userInfo.isagent==1" style="text-align: center" @click="$parent.goNav('/agentme')">
                <van-image round width=".9375rem" height=".9375rem" src="static/image/userCenter/hz.png" />
                <div style="font-size: 0.375rem">合营中心</div>
            </div>
            <div v-else style="text-align: center" @click="$parent.goNav('/activityInfo?id=5')">
                <van-image round width=".9375rem" height=".9375rem" src="static/image/userCenter/hz.png" />
                <div style="font-size: 0.375rem">合营中心</div>
            </div>
        </div>
        <van-cell-group inset style="margin: 0.625rem">
            <van-cell is-link v-if="$store.state.token" :border="false" style="font-size: 20px;" center title="修改密码" icon="static/image/userCenter/pwd.png" @click="$parent.goNav('/password')" />
            <van-cell is-link :border="false" style="font-size: 20px;" center title="个人资料" icon="static/image/userCenter/grzl.png" @click="$parent.goNav('/userinfo')" />
            <van-cell is-link :border="false" style="font-size: 20px;" center title="消息中心" icon="static/image/userCenter/yj.png" @click="$parent.goNav('/message')" />
            <!-- <van-cell is-link :border="false" style="font-size: 20px;" center title="福利中心" icon="static/image/userCenter/fl.png" @click="$parent.goNav('/welfare')" /> -->
            <!-- <van-cell is-link :border="false" style="font-size: 20px;" center title="我的会员" icon="static/image/userCenter/hy.png" @click="$parent.goNav('/vip')" /> -->
            <!-- <van-cell is-link :border="false" center title="意见反馈" icon="static/image/userCenter/advince.png" @click="$parent.goNav('/kefu')" /> -->
            <van-cell is-link v-if="$store.state.token" :border="false" style="font-size: 20px;" center title="安全退出" icon="static/image/userCenter/logout.png" @click="$parent.outLogin()" />
        </van-cell-group>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            moneyShow: false
        }
    },

    methods: {
        changMoneyShow() {
            this.moneyShow = !this.moneyShow;
        },
        async copyText(textToCopy) {

            if (navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(textToCopy).then(() => {
                    this.$notify({
                        type: 'success',
                        message: "复制成功"
                    });
                }).catch((error) => {
                    const errorMessage = `复制失败: ${error.message || "未知错误"}`;
                    this.$notify({
                        type: 'danger',
                        message: errorMessage
                    });
                    console.error("复制失败原因:", error);
                });
            } else {
                this.$notify({
                    type: 'danger',
                    message: "复制失败: 当前浏览器不支持剪贴板操作"
                });
            }
        },
        transall() {
            let that = this;
            that.$apiFun
                .post('/api/transall', {})
                .then(res => {
                    if (res.code !== 200) {
                        // that.$parent.showTost(0, res.message);
                    } else {
                        that.$parent.getBalance();
                    }
                })
                .catch(res => {});
        },
    },
    watch: {
        // 监听路由变化
        '$route'(to, from) {
            console.log("user", to, from)
            // 路由变化时重新请求数据
            if (to.path === '/user' && this.$store.state.token && this.$store.state.userInfo.transferstatus == 1) {
                this.transall();
            }

        },
    },
}
</script>

<style scoped>
::v-deep .van-cell__icon {
    font-size: 30px;
    /* 设置图标的大小 */
}

.van-icon__image {
    display: block;
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.wallet_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0.1875rem 0.4375rem rgba(140, 178, 214, 0.2);
    margin: 0.625rem;
    border: 0.0625rem solid #fff;
    border-radius: 0.625rem;
    background: linear-gradient(180deg,
            #fff 2%,
            #fefefe 60%,
            #f3f3f7 95%,
            #fff 100%);
    padding: 0.625rem 0.5rem;
}

.quick_bar {
    margin: 0.625rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    grid-gap: 0.3125rem;
    border-radius: 0.625rem;
    background-color: #fff;
    box-shadow: 0 0.1875rem 0.4375rem rgba(140, 178, 214, 0.2);
    box-sizing: border-box;
    padding: 0.625rem;
}
</style>
